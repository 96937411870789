import React from 'react';
import { graphql, Link } from 'gatsby';

// Components
import Layout from '../components/layout';
import SEO from '../components/seo';

// Assets
import Pattern from '../images/title-pattern.inline.svg';

// Styles
import '../styles/pages/winecellar.scss';
import Slider, { CurrentSlide, MobileNav, NextSlide } from '../components/slider';
import AnimatedArrow from '../components/animated-arrow';

const MainSection = ({ data }) => {
  const { pageTitle, pageDescription, pageImage, featured, listOfWines } = data;
  const parsedDescription = JSON.parse(pageDescription);
  const parsedFeatured = JSON.parse(featured);
  const parsedList = JSON.parse(listOfWines);

  return (
    <section className="main-section pt-40 lg:pt-44 4xl:pt-48">
      <div className="top-section grid mb-10 gap-y-10 px-6 md:px-0 md:mb-24 md:gap-y-0 lg:mb-32 xl:mb-0">
        <div className={`description md:col-start-2 md:col-end-3 lg:mb-16`}>
          <h1 className="leading-loose text-tuya-slate mb-6 text-3xl md:leading-snug md:text-5xl lg:text-6xl xl:text-8xl">{pageTitle}</h1>
          <div className={`grid gap-y-7 auto-rows-auto lg:mb-10`}>
            {parsedDescription.map((p, k) => (
              <p key={k} className="font-light text-tuya-dark m-0 4xl:leading-relaxed 4xl:text-xl">{p}</p>
            ))}
          </div>
          <Pattern className="hidden mt-10 lg:block" />
        </div>
        <div className="page-image pt-0 md:pt-24 md:col-start-4 md:col-end-5 lg:pt-5">
          <img
            className="relative z-10 w-full h-full object-cover"
            src={pageImage.sizes.src}
            srcSet={pageImage.sizes.srcSet}
            alt="Tuya Resturant"
          />
        </div>
      </div>

      <div className="mid-section grid border-0 border-b border-solid border-grey-300 lg:-mt-36">
        <div className="featured bg-grey-300 pt-14 px-6 py-14 md:pb-28 md:px-20 lg:px-32">
          <div className="grid auto-rows-auto gap-y-12 md:w-9/12">
            {parsedFeatured.map((feature, key) => (
              <div key={key}>
                <h3 className="text-tuya-slate text-3xl leading-10 mb-10 pb-2.5 border-b border-grey-500 inline-block md:mb-7 md:pb-0 md:border-0 md:block">{feature.title}</h3>
                <div className="grid gap-y-5">
                  {feature.items.map((item, index) => (
                    <div className="grid grid-cols-6 gap-4 justify-between leading-tight text-tuya-dark" key={index}>
                      <span className="col-span-4">{item.name}</span><em>{item.year}</em>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="list-section grid pt-14 lg:pt-24">
        <Slider carousel={data.carousel}>
          <NextSlide sliderId="first" className="lg:pt-14" noGrayscale/>
          <CurrentSlide sliderId="first" className="col-start-3 col-end-4 lg:pt-14" />
          <MobileNav sliderId="first" mt={4} className="mb-16 row-start-2 col-span-full" />
        </Slider>

        <div className="wines-list row-start-3 col-span-full px-6 md:p-20 lg:p-0 md:row-start-2 lg:row-start-1 lg:row-span-2 lg:col-start-5 lg:col-end-6 grid grid-flow-row gap-10">
          {parsedList.map(({ items, category }, key) => (
            <div key={key}>
              <h3 className="text-tuya-slate text-3xl leading-10 mb-6 pb-1 border-b border-grey-500 leading-relaxed inline-flex">
                {category}
              </h3>
              <div className="grid gap-y-5">
                {items.map((item, index) => (
                  <div className="grid grid-cols-6 gap-4 justify-between leading-tight text-tuya-dark" key={index}>
                    <span className="col-span-4">{item.name}</span>
                    <em className="col-start-6">{item.year}</em>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className={`
          row-start-4 col-span-full col-start-1 px-6 pt-10
          md:px-0 md:pt-0 md:row-start-3
          lg:row-start-2 lg:col-start-1 lg:col-end-4 lg:pl-10
          xl:col-start-3 xl:col-end-4
        `}>
          <div className="pl-16 pr-12 pt-7 pb-11 md:ml-20 md:w-96 lg:ml-0 lg:w-full lg:mt-36 bg-grey-200">
            <Pattern className="mb-6" />
            <p className="leading-relaxed font-light text-lg mb-6 text-grey-400">
              Fresh ingredients and the simplicity of home cooking. Each dish showcases the true essence of French cuisine.
            </p>
            <Link className="text-3xl text-tuya-slate-light" to="/jobs">
              Food Menu
              <AnimatedArrow className="h-3 text-tuya-dark" />
            </Link>
          </div>
        </div>

      </div>
    </section>
  );
};

const WinecellarPage = ({ data: graphqlData, location }) => {
  const data = graphqlData.datoCmsWinecellar;


  return (
    <Layout className="winecellar-page" pathname={location.pathname} alwaysBlack>
      <SEO title="Winecellar" />
      <MainSection data={data} />
    </Layout>
  );
};

export const query = graphql`
  query {
    datoCmsWinecellar {
      pageTitle
      pageDescription
      pageImage {
        sizes {
          src
          srcSet
        }
      }
      featured
      listOfWines
      carousel {
        id
        title
        image {
          sizes {
            src
            srcSet
          }
        }
      }
    }
  }
`;

export default WinecellarPage;
